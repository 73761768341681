
import { Component, Mixins } from "vue-property-decorator";
import { merchant } from "@/api/queries/merchants";
import { MerchantDetails } from "@/@types/Merchant";
import { Nullable } from "@/@types/BaseTypes";
import { startCase } from "lodash-core";
import { CURRENCY_SYMBOL } from "../utils/constants";
import { fullDateTime } from "@/utils/helpers/date-formatter";
import NotifierMixin from "@/mixins/NotifierMixin";
import BreakpointMixin from "@/mixins/BreakpointMixin";
import PaginatorMixin from "@/mixins/PaginatorMixin";
import LoaderMixin from "@/mixins/LoaderMixin";
import { enterprisePlanSubscribe } from "@/api/mutations/plan";

@Component({
  apollo: {
    merchant: {
      query: merchant,
      variables() {
        return { appUuid: this.$route.params.merchantUuid };
      },
      update({ merchant }: { merchant: MerchantDetails }) {
        if (merchant.subscribedEnterprisePlan) {
          merchant.plan = merchant.subscribedEnterprisePlan.name.toLowerCase();
          if (merchant.plan === "pro_starter") {
            this.selectedPlan = {
              planName: "Pro Starter",
              value: "pro_starter",
            };
            this.updateSelectedPlan();
          } else {
            this.selectedPlan = {
              planName: "Free Starter",
              value: "free_starter",
            };
          }
        }
        if (merchant.currentAppEnterprisePlan) {
          const startdate = new Date(
            merchant.currentAppEnterprisePlan.subscriptionStartAt
          );
          this.selectedStartDate = startdate.toISOString().substr(0, 10);

          const endDate = new Date(
            merchant.currentAppEnterprisePlan.subscriptionEndAt
          );
          this.seletedEndDate = endDate.toISOString().substr(0, 10);

          this.selectedDuration =
            merchant.currentAppEnterprisePlan.subscriptionMonths === 1
              ? "Monthly"
              : "Manual";
          this.isdatePickerDisable =
            merchant.currentAppEnterprisePlan.subscriptionMonths === 1;
        }
        return {
          ...merchant,
          displayedLaunchedAt: fullDateTime(merchant.launchedAt),
        };
      },
    },
  },
})
export default class Merchant extends Mixins(
  NotifierMixin,
  BreakpointMixin,
  PaginatorMixin,
  LoaderMixin
) {
  data() {
    return {
      isDurationDisabled: true,
      isdatePickerDisable: true,
      selectedStartDate: "",
      seletedEndDate: "",
      planOptions: [
        {
          planName: "Free Starter",
          value: "free_starter",
        },
        {
          planName: "Pro Starter",
          value: "pro_starter",
        },
      ],
      selectedDuration: "",
      durationOption: [
        {
          text: "Monthly",
        },
        {
          text: "Manual",
        },
      ],
    };
  }

  startAllowedDates(date) {
    if (this.selectedStartDate) {
      const today = new Date(this.selectedStartDate);
      const twoMonthsFromNow = new Date(
        today.getFullYear(),
        today.getMonth() + 2,
        today.getDate()
      )
        .toISOString()
        .substr(0, 10);
      // Enable dates that are before today or beyond two months from now
      return (
        date < today.toISOString().substr(0, 10) || date > twoMonthsFromNow
      );
    } else {
      // If no start date is selected, enable all dates
      return true;
    }
  }

  private merchant: Nullable<MerchantDetails> = null;

  private merchantUuid: Nullable<string> = null;

  private subscription_merchantUuid: Nullable<string> = null;

  private message = "";

  private subscription_message = "";

  private subscriptionEventEmitter = "subscription:confirmation";

  private setDefaultDates() {
    const today = new Date();
    const oneMonthAhead = new Date(today);
    oneMonthAhead.setMonth(oneMonthAhead.getMonth() + 1);

    this.selectedStartDate = today.toISOString().substr(0, 10);
    this.seletedEndDate = oneMonthAhead.toISOString().substr(0, 10);
  }
  private setProDefaultEndDate() {
    const today = new Date(this.selectedStartDate);
    const twoMonthAhead = new Date(today);
    twoMonthAhead.setMonth(twoMonthAhead.getMonth() + 2);

    this.seletedEndDate = twoMonthAhead.toISOString().substr(0, 10);
  }

  private updateSelectedPlan() {
    if (this.selectedPlan === "free_starter") {
      this.setDefaultDates();
      this.selectedDuration = { text: "Monthly" };
      this.isDurationDisabled = true;
      this.isdatePickerDisable = true;
    } else {
      this.setDefaultDates();
      if (this.selectedDuration === "Monthly") {
        this.isDurationDisabled = false;
        this.isdatePickerDisable = true;
      } else {
        this.isDurationDisabled = false;
        this.isdatePickerDisable = false;
      }
    }
  }

  private updateDuration() {
    if (this.selectedDuration === "Manual") {
      this.setProDefaultEndDate();
      this.isDurationDisabled = false;
      this.isdatePickerDisable = false;
    } else {
      this.setDefaultDates();
      this.isDurationDisabled = false;
      this.isdatePickerDisable = true;
    }
  }

  private linkGenerator(fbId: string) {
    return `https://m.me/${fbId}`;
  }

  private get convenienceFee() {
    return this.merchant?.accumulatedConvenienceFee || 0;
  }

  private get merchantLoaded() {
    return !!this.merchant;
  }

  private get appDisabled() {
    return !this.merchant?.launchedAt || !this.merchant?.activePage;
  }

  private get currentAppEnterprisePlan() {
    return this.merchantLoaded ? this.merchant?.currentAppEnterprisePlan : null;
  }

  private get currencySymbol() {
    return CURRENCY_SYMBOL;
  }

  private formattedPlan(plan: string) {
    if (/^pro_msme/.test(plan)) {
      return plan.replace("pro_", "").toUpperCase();
    }
    return startCase(plan.toLowerCase());
  }

  private async onSuccess(msg: string) {
    this.showSuccessAlert(msg);
    await this.$apollo.queries.merchant.refetch();
  }

  private onToggleSubscriptionConfirm(message: string) {
    if (this.merchant) {
      this.subscription_merchantUuid = this.merchant.uuid;
    }
    this.subscription_message = message;
  }

  private handleUpdatePlan() {
    this.loadingInProgress();

    const plan_type = this.selectedPlan.value ?? this.selectedPlan;

    this.$apollo
      .mutate({
        mutation: enterprisePlanSubscribe,
        variables: {
          input: {
            appUuid: this.subscription_merchantUuid,
            planSlug: plan_type,
            subscriptionStartDate: this.selectedStartDate,
            subscriptionEndDate: this.seletedEndDate,
          },
        },
        update: () => {
          this.subscription_merchantUuid = null;
          this.loadingDone();
          this.$apollo.queries.merchant.refetch();
          this.showSuccessAlert("Subscription Updated to " + plan_type);
        },
      })
      .catch((e: any) => {
        this.subscription_merchantUuid = null;
        this.showErrorAlert(e.message);
        this.loadingDone();
      });
  }
}
